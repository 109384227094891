import React from 'react'
import { SvgIcon, type SvgIconProps } from '@mui/material'

const LinkedinIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      d='M21.3438 1.03907H2.65625C2.23224 1.03475 1.82384 1.19882 1.52066 1.49527C1.21748 1.79171 1.04428 2.19632 1.03906 2.62032V21.3833C1.04522 21.8067 1.21883 22.2104 1.52191 22.506C1.82499 22.8017 2.23286 22.9653 2.65625 22.9609H21.3438C21.7678 22.9643 22.1759 22.7997 22.4789 22.5031C22.782 22.2064 22.9553 21.8019 22.9609 21.3779V2.61493C22.9534 2.19218 22.7793 1.78949 22.4764 1.49441C22.1736 1.19932 21.7666 1.03569 21.3438 1.03907Z'
      fill='#0076B2'
    />
    <path
      d='M4.28423 9.25616H7.53837V19.7266H4.28423V9.25616ZM5.9122 4.04523C6.28544 4.04523 6.65028 4.15593 6.96059 4.36332C7.2709 4.57072 7.51273 4.86549 7.65547 5.21035C7.79822 5.5552 7.83548 5.93465 7.76253 6.30069C7.68959 6.66672 7.50972 7.0029 7.24568 7.26669C6.98163 7.53048 6.64529 7.71003 6.27918 7.78263C5.91307 7.85523 5.53366 7.81761 5.18894 7.67453C4.84422 7.53145 4.54968 7.28935 4.34258 6.97884C4.13548 6.66834 4.02513 6.30338 4.02548 5.93015C4.02596 5.43007 4.22495 4.95064 4.57872 4.5972C4.9325 4.24376 5.41212 4.04523 5.9122 4.04523ZM9.57962 9.25616H12.699V10.6937H12.7421C13.177 9.8707 14.2371 9.00281 15.8202 9.00281C19.1156 8.99562 19.7266 11.1644 19.7266 13.9766V19.7266H16.4724V14.6324C16.4724 13.4195 16.4509 11.858 14.7816 11.858C13.1123 11.858 12.8284 13.1805 12.8284 14.5534V19.7266H9.57962V9.25616Z'
      fill='white'
    />
  </SvgIcon>
)

export default LinkedinIcon
