import React from 'react'
import { SvgIcon, type SvgIconProps } from '@mui/material'

const HomeIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox='0 0 20 21' {...props}>
    <path
      d='M2.5 8.00008L10 2.16675L17.5 8.00008V17.1667C17.5 17.6088 17.3244 18.0327 17.0118 18.3453C16.6993 18.6578 16.2754 18.8334 15.8333 18.8334H4.16667C3.72464 18.8334 3.30072 18.6578 2.98816 18.3453C2.67559 18.0327 2.5 17.6088 2.5 17.1667V8.00008Z'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7.5 18.8333V10.5H12.5V18.8333'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </SvgIcon>
)

export default HomeIcon
