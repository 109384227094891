import React from 'react'
import { SvgIcon, styled, type SvgIconProps } from '@mui/material'

const StyledSvgIcon = styled(SvgIcon)<SvgIconProps>({
  fill: 'none',
  stroke: 'currentcolor',
})

const SendIcon = (props: SvgIconProps) => (
  <StyledSvgIcon viewBox='0 0 24 24' {...props}>
    <path
      d='M22 2L11 13'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M22 2L15 22L11 13L2 9L22 2Z'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </StyledSvgIcon>
)

export default SendIcon
