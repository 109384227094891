import React, { useState } from 'react'
import { Box, Button, useMediaQuery } from '@mui/material'
import { RestrictedAccessDialog } from '../../../components/shared/Dialogs'
import { useTheme } from '@mui/material/styles'
import { Link as RouteLink } from 'react-router-dom'

const StartFreeTrial = () => {
  const theme = useTheme()
  const xsMatched = useMediaQuery(theme.breakpoints.down('xs'))
  const smMatched = useMediaQuery(theme.breakpoints.down('sm'))
  const mdMatched = useMediaQuery(theme.breakpoints.down('md'))

  const [restrictedAccessDialog, setRestrictedAccessDialog] = useState<boolean>(false)

  const handleClick = () => {
    setRestrictedAccessDialog(true)
  }

  const handleClose = () => {
    setRestrictedAccessDialog(false)
  }

  return (
    <>
      <Box
        sx={{
          width: 1,
          px: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: mdMatched ? 'center' : 'flex-start',
          my: mdMatched ? 3 : 1,
        }}
      >
        <Button variant='contained' component={RouteLink} to='/auth/register' >
          Start Free Trial
        </Button>
      </Box>
      <RestrictedAccessDialog open={restrictedAccessDialog} onClose={handleClose} />
    </>
  )
}

export default StartFreeTrial
