import React from 'react'
import { Box, Container, Grid, Typography } from '@mui/material'
import Vimeo from '@u-wave/react-vimeo'

const ImageComponent = () => {
  // return <Image src={src} alt={alt} />
  const videoId =
    'https://player.vimeo.com/video/941186530?h=7a57828abe&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100%',
        minWidth: '100%',
      }}
    >
      <Vimeo
        style={{ boxShadow: '2px 3px 10px rgba(0, 0, 0, 0.25)' }}
        video={videoId}
        // autoplay={true}
        muted={true}
      />
    </Box>
  )
}

export default ImageComponent
