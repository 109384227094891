import React from 'react'
import {
  Dialog,
  DialogContent,
  Typography,
  Box,
  Button,
  Stack,
  Divider,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material'
import { ToastContainer, toast } from 'react-toastify'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { type InferType } from 'yup'

import { disLikeFeedbackSchema } from '../../../lib/validation'
import { ThumbsUpIcon } from '../../Icons'
import TextField from '../TextField'
import { useSidebarContext } from '../../../contexts'
import baseUrl from '../../../config/baseUrl'

interface Props {
  open: boolean
  onClose: () => void
  historyId: string
  answer: string
}

type FormData = InferType<typeof disLikeFeedbackSchema>

const feedbackOptions = [
  {
    label: 'It is harmful/unsafe',
    value: 'It is harmful/unsafe',
  },
  {
    label: 'It isn’t true',
    value: 'It isn’t true',
  },
  {
    label: 'It isn’t helpful',
    value: 'It isn’t helpful',
  },
]

const DislikeFeedbackDialog = ({ open, onClose, historyId, answer }: Props) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormData>({
    resolver: yupResolver(disLikeFeedbackSchema),
    defaultValues: {
      message: '',
      feedback: [],
    },
  })

  const token = localStorage.getItem('token')
  const userId = localStorage.getItem('userId')

  const { setButtonsDisabled, getUserHistorybyThreadId, threadId } = useSidebarContext()

  const dislikeFeedback = async (data: any) => {
    try {
      const respo = await fetch(`${baseUrl}/api/like/addDislike`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          authorization: token as string,
        },
        body: JSON.stringify({
          HistoryId: historyId,
          UserId: userId,
          PromptResp: answer,
          FeedBack: data.message,
          DislikeType: data.feedback,
        }),
      })

      const respoData = await respo.json()

      if (respoData.status === true) {
        setButtonsDisabled(true)
        toast.success('Thanks for letting us know. We\'ll use your feedback to improve future prompts.')
        if (threadId) {
          await getUserHistorybyThreadId(threadId);
        }
      } else {
        toast.warning('error occur in submitting data')
      }
    } catch (error: any) {
      // console.log('dislike api error :', error.message)
      toast.error(error?.message)
    }
  }

  const onSubmit = (data: any) => {
    dislikeFeedback(data)
    onClose()
    reset({ message: '', feedback: [] })
  }

  return (
    <Dialog open={open} maxWidth='md' fullWidth>
      <DialogContent
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          p: 4,
        }}
      >
        <Stack spacing={3} width={1}>
          <Stack spacing={2} width={1}>
            <Box display='flex' alignItems='center' gap={2} width={1}>
              <Box
                display='flex'
                alignItems='center'
                color='error.main'
                sx={{ transform: 'rotate(-180deg)' }}
              >
                <ThumbsUpIcon alreadyLiked={false} alreadyDisliked={true} strokeColor='' />
              </Box>
              <Typography variant='body2' fontWeight={600} color='textSecondary'>
                Provide Additional Feedback
              </Typography>
            </Box>
            <Divider component='div' sx={{ borderColor: 'background.border' }} />
          </Stack>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
              <Stack spacing={2}>
                <Controller
                  name='message'
                  control={control}
                  render={({ field: { name, onChange, value } }) => (
                    <TextField
                      name={name}
                      onChange={onChange}
                      value={value}
                      error={errors.message?.message}
                      label='Feedback'
                      multiline
                      rows={4}
                      placeholder='Enter Your Feedback'
                    />
                  )}
                />
                <FormGroup sx={{ width: 'fit-content' }}>
                  <Controller
                    name='feedback'
                    control={control}
                    render={({ field: { name, onChange, value } }) => (
                      <>
                        {feedbackOptions.map((feedbackOption) => (
                          <FormControlLabel
                            key={feedbackOption.value}
                            label={feedbackOption.label}
                            componentsProps={{
                              typography: {
                                variant: 'subtitle1',
                              },
                            }}
                            control={
                              <Checkbox
                                value={feedbackOption.value}
                                checked={value.some(
                                  (existingValue) => existingValue === feedbackOption.value,
                                )}
                                onChange={(event, checked) => {
                                  if (checked) {
                                    onChange([...value, event.target.value])
                                  } else {
                                    onChange(value.filter((value) => value !== event.target.value))
                                  }
                                }}
                              />
                            }
                          />
                        ))}
                      </>
                    )}
                  />
                </FormGroup>
              </Stack>
              <Stack direction='row' justifyContent='flex-end' spacing={2} width={1}>
                <Button
                  variant='outlined'
                  sx={{ p: 0, maxWidth: 114, height: 44 }}
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button variant='contained' type='submit' sx={{ p: 0, maxWidth: 94, height: 44 }}>
                  Submit
                </Button>
              </Stack>
            </Stack>
          </form>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

export default DislikeFeedbackDialog
