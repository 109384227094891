import React, { useState, useEffect, useRef } from 'react'
import baseUrl from '../../config/baseUrl'
import { useNavigate } from 'react-router-dom'
import SearchIcon from '@mui/icons-material/Search'
import PropTypes from 'prop-types'
import SContext from '../../contexts/SidebarContext'
import OldSidebarContext from '../../contexts/Old_SidebarContext'
// import Collapse from '@mui/material/Collapse'
import { ToastContainer, toast } from 'react-toastify'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import Pagination from '../../components/Pagination'
import {
  Alert,
  Snackbar,
  Box,
  // Button,
  IconButton,
  Drawer,
  List,
  TextField,
  InputAdornment,
  Typography,
  ListItemButton,
  CircularProgress,
  ListItemText,
  Grid,
  Tooltip,
  Stack,
  Container,
  useMediaQuery,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'

import CloseIcon from '@mui/icons-material/Close'
import MenuIcon from '@mui/icons-material/Menu'
import HomeIcon from '@mui/icons-material/Home'
import EditNoteIcon from '@mui/icons-material/EditNote'

function Verifier() {
  // const userId = sessionStorage.getItem('userId')
  const anchor = 'left'

  const searchRef = useRef(null)
  const menuSearchRef = useRef(null)
  const { setOpenVerifier } = React.useContext(SContext)
  const [results, setResults] = useState([])
  const [searchResultBox, setSearchResultBox] = useState(false)
  // const [showButton, setShowButton] = useState(false)
  const [state, setState] = React.useState({
    left: false,
  })
  const [loader, setLoader] = useState(false)
  // const [openMenu, setOpenMenu] = useState(null)
  const [verifierAnswer, setVerifierAnswer] = useState(null)
  const [verifierQuestion, setVerifierQuestion] = useState(null)
  // let verifierQuestion = ''
  const [showIcon, setShowIcon] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [searchQuery, setSearchQuery] = useState('')
  // const [selectedOption, setSelectedOption] = useState('Acts'); // Default selected option

  // pagination
  const [totalPages, setTotalPages] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)

  // media query
  const theme = useTheme()
  const mdMatched = useMediaQuery(theme.breakpoints.down('md'))

  // snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarSeverity, setSnackbarSeverity] = useState('')
  const [snackbarMessage, setSnackbarMessage] = useState('')

  const navigate = useNavigate()

  const { answer } = React.useContext(OldSidebarContext)

  // --------------------- never gonaa true ---------------------
  if (searchResultBox && searchRef?.current?.value === 'never gonaa true') {
    // searchQuery && console.log(searchQuery)
    // console.log(searchResultBox)
    searchQuery && setSearchResultBox(true)
    setSearchQuery(searchRef?.current?.value.toLowerCase())
  }

  // const { lastAskedQuestion, answer, userStatus, handleSearchCount } =
  //   React.useContext(OldSidebarContext)

  useEffect(() => {
    document.title = 'Verifier - LawTech'
    // setCurrentPage(1);
  }, [])

  useEffect(() => {
    currentPage && currentPage !== 1 && handleSearchChange()
  }, [currentPage])

  useEffect(() => {
    setLoader(false)
    answer && setVerifierAnswer(answer)
  }, [answer])

  const queryParams = parseQueryString()
  // console.log('queryParams', queryParams)

  function parseQueryString() {
    const queryString = window.location.search.substring(1)
    // console.log('queryString', queryString)
    const pairs = queryString.split('&')
    // console.log('pairs', decodeURIComponent(pairs))
    const parsed = {}

    for (let i = 0; i < pairs.length; i++) {
      const [key, value] = pairs[i].split('=')
      parsed[decodeURIComponent(key)] = decodeURIComponent(value)
    }

    // console.log('parsed', parsed)
    return parsed
  }

  const objectFromQuery = queryParams
  // console.log('objectFromQuery', objectFromQuery)

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false)
  }

  const toEditorFromVerifier = async () => {
    const objectToSend = {
      qs: objectFromQuery?.qs ?? '',
      ans: objectFromQuery?.ans ?? '',
      hId: objectFromQuery?.hId ?? '',
    }

    let queryString = ''
    for (const key in objectToSend) {
      const value = encodeURIComponent(objectToSend[key])
      queryString += `${key}=${value}&`
    }

    // Remove the trailing ampersand
    queryString = queryString.slice(0, -1)

    const url = '/editor?' + queryString

    navigate(url)
    // navigate(objectFromQuery?.hId ? `/editor/${objectFromQuery?.hId}` : '/editor')
  }
  // const handleClick = (index) => {
  //   setOpenMenu(openMenu === index ? null : index)
  // }
  const handleChange = (e) => {
    if (e.target.value === '') {
      setShowIcon(false)
    }
    setSearchText(e.target.value)
    setShowIcon(true)
  }

  const handleClearSearch = (refName) => {
    // console.log(refName)
    setSearchQuery('')
    setResults([])
    setTotalPages(1)
    setCurrentPage(1)
    setSearchResultBox(false)
    setVerifierQuestion('')
    setVerifierAnswer('')
    setSearchText('')
    setShowIcon(false)

    // setShowButton(false)
  }

  const handleSearchChange = async () => {
    let searchTerm = ''
    if (searchRef?.current?.value?.length !== 0 || menuSearchRef?.current?.value?.length !== 0) {
      searchTerm = searchRef?.current?.value || menuSearchRef?.current?.value

      try {
        const res = await fetch(
          `${baseUrl}/api/admin/getSectionsQue?search=${searchTerm.toLowerCase()}&page=${currentPage}`,
        )
        // console.log('handleSearchChange res', res)

        const data = await res.json()

        if (!res.ok) {
          //   console.log('error');
          //   setSnackbarMessage('Something went wrong while searching');
          //   setSnackbarSeverity('error');
          //   setSnackbarOpen(true);
          //   return
          // } else if (res.status === 404) {
          setSnackbarMessage(data?.message)
          setSnackbarSeverity('info')
          setSnackbarOpen(true)
          return
        }

        // setData(searchResult);
        setTotalPages(data?.totalPages)
        setResults(data?.data)

        // console.log(data);
      } catch (error) {
        // console.log(error);
        toast.error(error)
      }
    }
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  // const filteredResults = results.filter(
  //   (item) =>
  //     item.title.toLowerCase().includes(searchQuery) ||
  //     item.subMenus.some((item) => item.toLowerCase().includes(searchQuery)),
  // )

  const handleEnterKeyPress = async (event) => {
    // console.log(event.target.name)
    if (event.key === 'Enter') {
      event.preventDefault()
      handleSearchChange()
    }
    if (event.key === 'Backspace' || event.key === 'Delete') {
      if (searchRef?.current?.value.length === 0) {
        setResults([])
      }
    }
  }

  const handleSubMenuClick = async (item) => {
    setVerifierQuestion(item)
    // console.log('Hello World', item)
    setSearchResultBox(false)
    // setShowButton(true)
    setLoader(true)

    try {
      const response = await fetch(`${baseUrl}/api/admin/getsection`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          section_number: item,
        }),
      })
      // console.log('verifier response', response)
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`)
      }

      const json = await response.json()
      // console.log('verifier json ', json)
      setVerifierAnswer(json?.data)
      setLoader(false)
      setState({ left: false })
    } catch (error) {
      setLoader(false)
      setVerifierAnswer(null)
      // console.log('error', error)
      toast.error(error)

      // console.log('verifierAnswer', verifierAnswer)
    }
  }

  // const filteredResults = results
  //   .map((result) => ({
  //     ...result,
  //     subMenus: result.subMenus.filter((item) => item.toLowerCase().includes(searchQuery)),
  //   }))
  //   .filter((result) => result.subMenus.length > 0)

  const toggleDrawer =
    (anchor = 'left', open = true) =>
    (event) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return
      }
      if (open === false) {
        setOpenVerifier(false)
      }
      setState({ ...state, [anchor]: open })
    }

  const MarkdownRenderer = (props) => {
    const { textToDisplay } = props
    // console.log('message', message)

    return (
      <Box
        style={{
          minHeight: '100%',
          maxHeight: 'calc(100vh - 250px)',
          width: '100%', // Fixed width for the container
          overflowY: 'auto',
          textAlign: 'justify',
          padding: '10px',
          maxWidth: '100%',
          wordBreak: 'break-word', // Ensures words wrap properly
        }}
      >
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          components={{
            // Handling specific elements like paragraphs, code blocks, and inline code
            p: ({ node, ...props }) => <p style={{ wordBreak: 'break-word' }} {...props} />,
            code: ({ node, ...props }) => (
              <code
                style={{
                  wordBreak: 'break-word', // Ensures long code lines break
                  whiteSpace: 'pre-wrap', // Keeps newlines and spaces
                  maxWidth: '100%',
                  display: 'block', // Makes sure the code doesn't overflow horizontally
                  overflowX: 'auto', // In case there's still overflow, adds scroll only for code
                }}
                {...props}
              />
            ),
            a: ({ node, ...props }) => (
              <a
                style={{
                  wordBreak: 'break-all', // Breaks long links properly
                }}
                {...props}
              />
            ),
          }}
        >
          {textToDisplay}
        </ReactMarkdown>
      </Box>
    )
  }

  MarkdownRenderer.propTypes = {
    textToDisplay: PropTypes.string.isRequired,
  }

  return (
    <>
      {/* <Container maxWidth='false' style={{
        margin: 0,
        padding: 0,
        boxSizing: 'border-box',
        position: 'relative',
        width: '100dvw',
        height: '100dvh',
        backgroundColor: '#ecece6'
      }}>
        <Box style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',

          backgroundColor: 'white',
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
        }}>
          <Box>LeftMenu</Box>
          <Box>Heading</Box>
          <Box>Right Menu</Box>
        </Box>
      </Container> */}
      {/* ================================================================ */}
      <Container
        style={{
          maxHeight: '100vh',
          minWidth: '100%',
          position: 'relative',
          backgroundColor: '#eceaea',
        }}
      >
        <ToastContainer />
        {loader && (
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
              zIndex: '10001',
            }}
          >
            {/* <h1>Loading ...</h1> */}
            <CircularProgress />
          </Box>
        )}

        {/* -------------------------------- Drawer Hidden start -------------------------------- */}
        <Box>
          <React.Fragment key={anchor}>
            <Drawer
              anchor={anchor}
              open={state[anchor]}
              PaperProps={{
                sx: {
                  minWidth: { xs: '75vw', sm: '60vw', md: '50vw' },
                  maxWidth: { xs: '75vw', sm: '60vw', md: '50vw' },
                },
              }}
              // open={true}
              // style={{ maxWidth: '40vw', minWidth: '30vw' }}
              onClose={toggleDrawer(anchor, false)}
            >
              {/*  close drawer button */}
              <Box
                style={{
                  padding: '5px 15px',
                  marginTop: '10px',
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                }}
              >
                <Tooltip title='Home' placement='right'>
                  <IconButton
                    style={{ width: '32px', height: '32px' }}
                    aria-label='close'
                    onClick={() => {
                      navigate(
                        objectFromQuery?.hId ? `/NewChat/${objectFromQuery?.hId}` : '/NewChat',
                      )
                    }}
                  >
                    <HomeIcon
                      style={{ color: '#278f42', width: '32px', height: '32px' }}
                      aria-label='home'
                    />
                  </IconButton>
                </Tooltip>
                {!mdMatched && (
                  <Tooltip title='Editor' placement='left'>
                    <IconButton
                      style={{ color: '#ff0000', width: '32px', height: '32px' }}
                      aria-label='close'
                      onClick={toEditorFromVerifier}
                    >
                      <EditNoteIcon />
                    </IconButton>
                  </Tooltip>
                )}

                <Tooltip title='Close' placement='left'>
                  <IconButton
                    style={{ color: '#ff0000', width: '32px', height: '32px' }}
                    aria-label='close'
                    onClick={toggleDrawer(anchor, false)}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </Box>

              <Box sx={{ padding: '0px 10px' }}>
                <TextField
                  inputRef={menuSearchRef}
                  value={searchText}
                  name='nameMenuSearchRef'
                  variant='outlined'
                  placeholder='Enter text to search'
                  fullWidth
                  margin='normal'
                  onChange={handleChange}
                  onKeyDown={handleEnterKeyPress}
                  autoComplete='off'
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start' style={{ margin: 0, padding: 0 }}>
                        <IconButton onClick={handleSearchChange}>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position='start' style={{ margin: 0, padding: 0 }}>
                        {showIcon && (
                          <Tooltip title='Clear search' placement='right'>
                            <IconButton
                              onClick={() => {
                                handleClearSearch()
                              }}
                            >
                              <CloseIcon style={{ color: 'red' }} />
                            </IconButton>
                          </Tooltip>
                        )}
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      paddingLeft: 1,
                      paddingRight: 1,
                    },
                    '& .MuiOutlinedInput-root .MuiInputBase-input': {
                      paddingLeft: '5px',
                    },
                  }}
                  // value={searchQuery}
                  // onChange={handleSearchChange}
                  // value={searchQuery}
                  // onChange={handleSearchChange}
                />
              </Box>

              {/* pagination */}
              <Grid
                container
                style={{
                  display: 'flex',
                  overflow: 'hidden',
                  // position: 'fixed',
                  // bottom: 5,
                  marginBottom: '20px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'transparent',
                  padding: '15px 0 30px 10px',
                  lineHeight: '50px',
                  zIndex: 11,
                }}
              >
                {searchText && (
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                )}
              </Grid>

              <List component='div' disablePadding>
                {results.map((item) => (
                  <ListItemButton
                    sx={{
                      pl: 4,
                      mt: '.25rem',
                      mb: '.2rem',
                      bgcolor: 'rgb(210, 228, 245)',
                      color: '#000',
                      '&:hover': { backgroundColor: 'rgb(200, 218, 235)' },
                    }}
                    key={item}
                    onClick={() => handleSubMenuClick(item)}
                  >
                    <ListItemText primary={item} />
                  </ListItemButton>
                ))}
              </List>
            </Drawer>
          </React.Fragment>
        </Box>
        {/* -------------------------------- Drawer Hidden end -------------------------------- */}

        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            // padding: '15px 0 30px 10px',
            // lineHeight: '50px',
            // zIndex: 11
          }}
        >
          <Tooltip title='Open Search bar'>
            <IconButton
              style={{
                color: '#278f42',
                width: '32px',
                height: '32px',
              }}
              aria-label='close'
              onClick={toggleDrawer(anchor, true)}
            >
              <MenuIcon />
            </IconButton>
          </Tooltip>

          {/* ------------------- Heading --------------------- */}
          <Box>
            <Typography
              variant={mdMatched ? 'h4' : 'h3'}
              style={{
                marginBottom: '15px',
                color: 'navy',
                textAlign: 'center',
                fontWeight: 'bold',
              }}
            >
              Verify AI Response
            </Typography>
          </Box>

          <Box
            style={{
              marginRight: '20px',
            }}
          >
            <Tooltip title='Home' placement='bottom'>
              <IconButton
                aria-label='home'
                sx={{ width: '32px', height: '32px', marginRight: '8px' }}
                onClick={() => {
                  navigate('/NewChat')
                }}
              >
                <HomeIcon sx={{ color: 'navy' }} />
              </IconButton>
            </Tooltip>
            {!mdMatched && (
              <Tooltip title='Editor' placement='bottom'>
                <IconButton
                  aria-label='editor'
                  sx={{ width: '32px', height: '32px' }}
                  onClick={() => {
                    navigate('/editor')
                  }}
                >
                  <EditNoteIcon sx={{ color: 'navy' }} />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>

        <Grid container spacing={1}>
          <Grid xs={12} md={6}>
            <Box
              style={{
                backgroundColor: '#f4f4f4',
                minHeight: '100%',
                minwidth: '49%',
                maxWidth: '100%',
                zIndex: '10000',
                // marginTop: '90px',
              }}
            >
              <Box sx={{ pt: 2, px: 1 }}>
                <Typography textAlign={'center'} style={{ fontWeight: 'bold', color: 'navy' }}>
                  Verifier
                </Typography>

                <Stack sx={{ p: '10px', bgcolor: '#f9f9f9', color: '#777' }}>
                  <Box sx={{ bgcolor: '#f4f4f4', color: '#000' }}>
                    <MarkdownRenderer textToDisplay={verifierQuestion} />
                  </Box>

                  <Box
                    style={{
                      heigth: '100%',
                      overflowY: 'auto',
                    }}
                  >
                    <MarkdownRenderer textToDisplay={verifierAnswer} />
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Grid>
          <Grid xs={12} md={6}>
            <Box
              style={{
                backgroundColor: '#e0dfdf',
                minHeight: '100%',
                minwidth: '49%',
                maxWidth: '100%',

                zIndex: '10000',
              }}
            >
              <Box sx={{ pt: 2, px: 1 }}>
                <Typography textAlign={'center'} style={{ fontWeight: 'bold', color: 'navy' }}>
                  AI Response
                </Typography>
                {/* <hr /> */}
                <Typography variant='h6' color='navy'>
                  Question
                </Typography>
                <MarkdownRenderer textToDisplay={objectFromQuery?.qs} />
                <Typography variant='h6' color='green'>
                  Answer
                </Typography>
                <Box
                  style={{
                    border: '1px solid #777',
                    minHeight: 'calc(100vh - 170px)',
                    borderRadius: '5px',
                  }}
                >
                  <MarkdownRenderer textToDisplay={objectFromQuery?.ans} />
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>

        {/* Last Asked and answered queston */}
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Container>
    </>
  )
}

export default Verifier
