import React, { useEffect } from 'react'
import LawTechLogo from '../../../../assets/images/Lawtech.png'
import LeftDrawer from './LeftDrawer'
import RightDrawer from './RightDrawer'
import { Grid, Button, Box, Typography, } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

function Editor() {

  useEffect(() => {
    document.title = 'Editor - LawTech'
  }, [])

  const navigate = useNavigate()

  const queryParams = parseQueryString()
  // console.log('queryParams', queryParams)

  function parseQueryString() {
    const queryString = window.location.search.substring(1)
    // console.log('queryString', queryString)
    const pairs = queryString.split('&')
    // console.log('pairs', decodeURIComponent(pairs))
    const parsed = {}

    for (let i = 0; i < pairs.length; i++) {
      const [key, value] = pairs[i].split('=')
      parsed[decodeURIComponent(key)] = decodeURIComponent(value)
    }

    // console.log('parsed', parsed)
    return parsed
  }

  const objectFromQuery = queryParams
  // console.log('objectFromQuery', objectFromQuery)

  return (
    <>
      {/* Top bar start */}

      <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#e5e5e5', padding: '5px', position: 'sticky', top: 0, left: 0, zIndex: 1000 }}>
        <img
          src={LawTechLogo}
          alt='logo'
          style={{
            width: '48px',
            height: 'auto',
            // marginTop: '10px',
            marginLeft: '10px',
          }}
        />

        <Typography
          variant='h4'
          textAlign={'center'}
          style={{
            fontWeight: '700',
            color: '#093F6B',
          }}
        >
          Edit Response
        </Typography>

        <Button
          sx={{
            zIndex: '10000',
            padding: '1px 5px',
            width: '100px',
            height: '35px',
            marginRight: '10px',
            color: 'black',
            borderWidth: '2px'
          }}
          variant='outlined'
          color='primary'
          onClick={() => {
            // console.log('objectFromQuery', objectFromQuery);
            navigate(objectFromQuery?.hId ? `/NewChat/${objectFromQuery?.hId}` : '/NewChat')
          }
          }
        >
          <ArrowBackIcon /> Back
        </Button>
      </Box>
      {/* Top bar end */}

      <Box
        sx={{
          margin: 0,
          padding: 0,
          boxSizing: 'border-box',
          height: 'calc(100dvh - 4rem)',
          width: '100dvw',
        }}
      >
        <Grid
          container
          // spacing={2}
          sx={{
            height: '100%',
          }}
        >
          {/* First Part */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              // backgroundColor: 'lightblue', // Optional: to visually distinguish parts
            }}
          >
            <Box
              sx={{
                height: '100%',
                width: '100%',
                overflow: 'auto', // To allow scrolling if content overflows

              }}
            >
              {/* Place your component here */}
              <Box
                sx={{
                  maxWidth: '100%', // Ensure the component fits within the outer Box
                  maxHeight: '100%',
                  backgroundColor: 'white', // Optional: Content background

                }}
              >
                <LeftDrawer />
              </Box>
            </Box>
          </Grid>

          {/* Second Part */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              // backgroundColor: 'lightgreen', // Optional: to visually distinguish parts
            }}
          >
            <Box
              sx={{
                height: '100%',
                width: '100%',
                overflow: 'auto',
              }}
            >
              {/* Place your component here */}
              <Box
                sx={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  backgroundColor: 'white', // Optional: Content background
                }}
              >
                <RightDrawer
                  ans={objectFromQuery?.ans}
                  qs={objectFromQuery?.qs}
                  hId={objectFromQuery?.hId}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  )

}

export default Editor
