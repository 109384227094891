import React from 'react'
import { SvgIcon, styled, type SvgIconProps } from '@mui/material'

const StyledSvgIcon = styled(SvgIcon)<SvgIconProps>({
  fill: 'none',
  stroke: 'currentcolor',
})

const ChevronDownIcon = (props: SvgIconProps) => (
  <StyledSvgIcon viewBox='0 0 24 24' {...props}>
    <path
      d='M6 9L12 15L18 9'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </StyledSvgIcon>
)

export default ChevronDownIcon
