import React from 'react'
import { Dialog, DialogContent, Typography, IconButton } from '@mui/material'
import { Player } from '@lottiefiles/react-lottie-player'
import { CloseIcon } from '../../Icons'

interface Props {
  open: boolean
  onClose: () => void
}

const SuccessfullyDialog = ({ open, onClose }: Props) => {
  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ sx: { maxWidth: 407 } }}>
      <IconButton
        disableRipple
        sx={{
          position: 'absolute',
          top: 16,
          right: 16,
          color: 'text.secondary',
          p: 0,
          width: 20,
          height: 20,
        }}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: 3, p: 6 }}
      >
        <Player
          autoplay
          loop
          src='https://lottie.host/730100da-8fa8-4e9f-a6fc-7b6f75188608/yZzI8eIuRF.json'
          style={{ height: 120, width: 120 }}
        />
        <Typography variant='h5'>Congratulations!</Typography>
        <Typography align='center'>
          You have been successfully registered on this platform
        </Typography>
      </DialogContent>
    </Dialog>
  )
}

export default SuccessfullyDialog
