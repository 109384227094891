import React from 'react'
import { SvgIcon, type SvgIconProps } from '@mui/material'

const SchoolOutlinedIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox='0 0 40 40' {...props}>
    <rect width='40' height='40' rx='4' fill='#FBE0EC' />
    <path
      d='M30 18V24M10 18L20 13L30 18L20 23L10 18Z'
      stroke='#233A48'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14 20V25C17 28 23 28 26 25V20'
      stroke='#233A48'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </SvgIcon>
)

export default SchoolOutlinedIcon
