import React from 'react'
import { SvgIcon, type SvgIconProps } from '@mui/material'

const LibraryOutlinedIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox='0 0 40 40' {...props}>
    <g opacity='0.8'>
      <rect width='40' height='40' rx='4' fill='#20B8FE' fillOpacity='0.2' />
      <path
        d='M24 14L28 28'
        stroke='#233A48'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20 14V28'
        stroke='#233A48'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16 16V28'
        stroke='#233A48'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 12V28'
        stroke='#233A48'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
  </SvgIcon>
)

export default LibraryOutlinedIcon
