import React, { useState } from 'react'
import { IconButton, type IconButtonProps, styled } from '@mui/material'

import { DislikeFeedbackDialog } from '../../../../../components/shared/Dialogs'
import { ThumbsUpIcon } from '../../../../../components/Icons'
import { useSidebarContext } from '../../../../../contexts'
import { toast } from 'react-toastify'

const StyledIconButton = styled(IconButton)<IconButtonProps>(({ theme }) => ({
  p: 0,
  width: 24,
  height: 24,
  color: theme.palette.background.border,
  transform: 'rotateZ(180deg)',
  '&:hover': {
    color: theme.palette.error.main,
    backgroundColor: 'transparent',
  },
}))

interface Props {
  historyId: string
  answer: string
  alreadyLiked: boolean
  alreadyDisliked: boolean
}

const DisLikeButton = ({ historyId, answer, alreadyDisliked }: Props) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const { buttonsDisabled } = useSidebarContext()

  const handleClick = () => {
    if (alreadyDisliked) {
      toast.info('You already disliked this question.')
      return;
    }
    setOpenDialog(true)
  }

  const handleClose = () => {
    setOpenDialog(false)
  }

  return (
    <>
      <StyledIconButton onClick={handleClick}>
        <ThumbsUpIcon alreadyLiked={false} alreadyDisliked={alreadyDisliked} strokeColor='#fc0808' />
      </StyledIconButton>
      <DislikeFeedbackDialog
        open={openDialog}
        onClose={handleClose}
        historyId={historyId}
        answer={answer}
      />
    </>
  )
}

export default DisLikeButton
