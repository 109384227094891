import React from 'react'
import {
  FormControl,
  InputLabel,
  FormHelperText,
  InputBase,
  Select,
  MenuItem,
  styled,
  type TypographyProps,
  type MenuItemProps,
  Typography,
} from '@mui/material'
import { ChevronDownIcon } from '../../Icons'

interface Props {
  type?: string
  name: string
  label?: string
  value: string
  placeholder?: string
  error?: string
  isRequired?: boolean
  disabled?: boolean
  inputRef?: React.RefObject<HTMLInputElement>
  options: Array<{ label: string; value: string | number }>
  onChange?: (event: { target: { value: string } }) => void
}
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}
const PlaceholderItem = styled(MenuItem)<MenuItemProps>({
  display: 'none',
})

const Placeholder = styled(Typography)<TypographyProps>({
  color: 'rgba(0, 0, 0, 0.1)',
  fontSize: 16,
  lineHeight: '1.4375em',
})

const SelectField = ({
  label,
  name,
  value,
  placeholder,
  error,
  isRequired,
  disabled,
  options,
  onChange,
}: Props) => {
  const hasError = !!error
  return (
    <FormControl fullWidth error={hasError} disabled={disabled}>
      <InputLabel htmlFor={name} shrink={true} required={isRequired}>
        {label}
      </InputLabel>
      <Select
        value={value}
        name={name}
        displayEmpty
        onChange={onChange}
        placeholder={placeholder}
        input={<InputBase placeholder={placeholder} />}
        IconComponent={ChevronDownIcon}
        MenuProps={MenuProps}
      >
        {placeholder && (
          <PlaceholderItem disabled value=''>
            <Placeholder variant='caption'>{placeholder}</Placeholder>
          </PlaceholderItem>
        )}
        {options?.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {hasError && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}

export default SelectField
