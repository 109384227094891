import React from 'react'
import { SvgIcon, styled, type SvgIconProps } from '@mui/material'

const StyledSvgIcon = styled(SvgIcon)<SvgIconProps>({
  fill: 'none',
  stroke: 'currentcolor',
  width: 20,
  height: 20,
})


const SearchIcon = (props: SvgIconProps) => (
  <StyledSvgIcon viewBox='0 0 24 24' {...props}>
    <path
      d='M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M21 21L16.65 16.65'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </StyledSvgIcon>
)

export default SearchIcon
