import React from 'react'
import { SvgIcon, styled, type SvgIconProps } from '@mui/material'

const StyledSvgIcon = styled(SvgIcon)<SvgIconProps>({
  fill: 'none',
  stroke: 'currentcolor',
})

const ChevronLeftIcon = (props: SvgIconProps) => (
  <StyledSvgIcon viewBox='0 0 24 24' strokeWidth='1.5' {...props}>
    <path
      d='M15 18L9 12L15 6'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </StyledSvgIcon>
)

export default ChevronLeftIcon
