import React from 'react'
import { SvgIcon, type SvgIconProps } from '@mui/material'

const ListCheckOutlinedIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox='0 0 40 40' {...props}>
    <rect width='40' height='40' rx='4' fill='#67E5CF' fillOpacity='0.2' />
    <path
      d='M18 14H29'
      stroke='#233A48'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M18 20H29'
      stroke='#233A48'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M18 26H29'
      stroke='#233A48'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11 14L12 15L14 13'
      stroke='#233A48'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11 20L12 21L14 19'
      stroke='#233A48'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11 26L12 27L14 25'
      stroke='#233A48'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </SvgIcon>
)

export default ListCheckOutlinedIcon
