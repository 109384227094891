import { createTheme } from '@mui/material/styles';
import components from './components'

const theme = createTheme({
  components: {
    ...components,
    MuiContainer: {
      styleOverrides: {
        root: ({ theme }) => ({
          [theme.breakpoints.down('xl')]: {
            paddingLeft: 30,
            paddingRight: 30,
          }
        }),
        maxWidthXl: ({ theme }) => ({
          [theme.breakpoints.down('xl')]: {
            paddingLeft: theme.spacing(10),
            paddingRight: theme.spacing(10),
          }
        })
      }
    },
  },
  typography: {
    fontFamily: 'Poppins, sans-serif',
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightLight: 600,
    fontWeightBold: 700,
    h1: {
      fontSize: 36,
      fontWeight: 600,
      lineHeight: '54px',
      color: '#18191F',
    },
    h2: {
      fontSize: 34,
      fontWeight: 600,
      lineHeight: '51px',
    },
    h3: {
      fontSize: 28,
      fontWeight: 600,
      lineHeight: '42px',
      color: '#000000',
    },
    h4: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: '32px',
      color: '#18191F',
    },
    h5: {
      fontSize: 18,
      fontWeight: 500,
      lineHeight: '27px',
      color: '#233A48'
    },
    h6: {
      fontFamily: 'Inter, sans-serif',
      fontSize: 18,
      fontWeight: 700,
      lineHeight: '28px',
      color: '#233A48'
    },
    body1: {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '26px',
    },
    body2: {
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '24px',
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '21px',
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '21px',
    },
    caption: {
      fontWeight: 400,
      fontSize: 20,
      lineHeight: '30px',
    },
    link: {
      fontWeight: 500,
      fontSize: 12,
      lineHeight: '18px',
    },
    bold: {
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '24px',
    },
    extraBold: {
      fontWeight: 700,
      fontSize: 24,
      lineHeight: '36px',
    }
  },
  palette: {
    primary: {
      main: '#FBCF24',
      light: '#587DBD',
    },
    secondary: {
      main: '#233A48',
    },
    text: {
      primary: '#707070',
      secondary: '#233A48',
      warning: '#D9DBE1',
      contrastText: '#2C2A2A',
    },
    success: {
      main: '#6FCF97'
    },
    error: {
      main: '#EB5757',
      light: '#CF0909'
    },
    common: {
      black: '#233A48',
      gray: '#4F4F4F',
      gray2: '#E0E0E0',
      gray3: '#F1F1F1',
      light: '#828282',
      blue: '#2F80ED',
      lightBlue: '#0076B2'
    },
    background: {
      default: '#FAFAFA',
      main: '#FBFAF6',
      light: '#F9F9F9',
      dark: '#F8F8F8',
      yellow: '#F7B629',
      border: '#BDBDBD',
      divider: 'linear-gradient(90deg, rgba(192, 204, 218, 0.1) 0%, rgba(192, 204, 218, 0.6) 50.38%, rgba(192, 204, 218, 0.1) 100%)'
    },
  },
  zIndex: {
    appBar: 1300
  }
});

export default theme;
