import React from 'react'
import { ToggleButton, Stack, styled, type ToggleButtonProps, Box } from '@mui/material'
import { FilterIcon, HistroyIcon } from '../../../../components/Icons'
import PsychologyIcon from '@mui/icons-material/Psychology'
interface Props {
  selected: number
  onClick: (selected: number) => void
}

const StyledButton = styled(ToggleButton)<ToggleButtonProps>(({ theme }) => ({
  width: 121,
  height: 45,
  color: theme.palette.text.primary,
  border: 'none',
  textTransform: 'capitalize',
  borderRadius: 0,
  padding: 0,
  fontSize: 14,
  lineHeight: '21px',
  borderBottomWidth: 3,
  borderBottomStyle: 'solid',
  borderBottomColor: theme.palette.background.default,
  '&.Mui-selected': {
    color: theme.palette.secondary.main,
    borderBottomWidth: 3,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.primary.main,
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  '&:hover': {
    backgroundColor: 'transparent',
  },
}))

const Tabs = ({ selected, onClick }: Props) => {
  return (
    <Stack direction='row' px='10px' justifyContent='space-between' bgcolor='background.default'>
      <StyledButton
        disableRipple
        value={1}
        selected={selected === 1}
        onClick={() => {
          onClick(1)
        }}
      >
        <HistroyIcon strokeWidth={2} sx={{ mr: 1.5, strokeWidth: 2 }} />
        History
      </StyledButton>

      <StyledButton
        disableRipple
        value={2}
        selected={selected === 2}
        onClick={() => {
          onClick(2)
        }}
      >
        <PsychologyIcon /> Prompt Hints
      </StyledButton>
    </Stack>
  )
}

export default Tabs
