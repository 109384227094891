import React from 'react'
import { SvgIcon, styled, type SvgIconProps } from '@mui/material'

const StyledSvgIcon = styled(SvgIcon)<SvgIconProps>({
  fill: 'none',
  stroke: 'currentcolor',
  width: 20,
  height: 20,
})

const HistroyIcon = (props: SvgIconProps) => (
  <StyledSvgIcon strokeWidth={1.5} viewBox='0 0 20 21' {...props}>
    <path
      d='M2.5 3V7.16667H6.66667'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M2.54167 11.3332C2.72625 13.0048 3.46725 14.5657 4.64565 15.7655C5.82405 16.9652 7.37148 17.7341 9.03941 17.9487C10.7073 18.1633 12.399 17.8111 13.8427 16.9486C15.2864 16.0862 16.3983 14.7636 17 13.1932C17.6016 11.6228 17.658 9.89584 17.1601 8.28955C16.6622 6.68327 15.639 5.29092 14.2546 4.33611C12.8703 3.38131 11.2052 2.91945 9.52686 3.02473C7.84848 3.13 6.25418 3.79628 5 4.91658L2.5 7.16658'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10 6.33325V10.4999L13.3333 12.1666'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </StyledSvgIcon>
)

export default HistroyIcon
