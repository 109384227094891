import React from 'react'
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { DatabaseIcon, UserCogIcon } from '../../components/Icons'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'
interface Props {
  selected: number
  onClick: (selected: number) => void
}

const LeftSidebar = ({ selected, onClick }: Props) => {
  return (
    <List
      component='div'
      disablePadding
      sx={{
        bgcolor: 'common.white',
        width: 232,
        pt: 2.5,
        borderRight: '1px solid #F2F2F2',
        borderRadius: 1,
      }}
    >
      <ListItemButton
        disableRipple
        selected={selected === 1}
        onClick={() => {
          onClick(1)
        }}
        sx={{
          '&:hover': {
            backgroundColor: 'transparent',
          },
          '&.Mui-selected': {
            borderLeft: '3px solid',
            borderLeftColor: 'primary.main',
            backgroundColor: 'transparent',
            color: 'text.secondary',
            '&:hover': {
              backgroundColor: 'transparent',
            },
            '& .MuiTypography-root': {
              fontWeight: 500,
            },
          },
        }}
      >
        <ListItemIcon sx={{ minWidth: 20, mr: 2 }}>
          <UserCogIcon sx={{ width: 20, height: 20, fill: 'none', stroke: 'currentcolor' }} />
        </ListItemIcon>
        <ListItemText sx={{ m: 0 }} primary='Profile Settings' />
      </ListItemButton>

      {/* ========================== Data Settings ========================== */}

      <ListItemButton
        disableRipple
        selected={selected === 2}
        onClick={() => {
          onClick(2)
        }}
        sx={{
          '&:hover': {
            backgroundColor: 'transparent',
          },
          '&.Mui-selected': {
            borderLeft: '3px solid',
            borderLeftColor: 'primary.main',
            backgroundColor: 'transparent',
            color: 'text.secondary',
            '&:hover': {
              backgroundColor: 'transparent',
            },
            '& .MuiTypography-root': {
              fontWeight: 500,
            },
          },
        }}
      >
        <ListItemIcon sx={{ minWidth: 20, mr: 2 }}>
          <DatabaseIcon sx={{ width: 20, height: 20, fill: 'none', stroke: 'currentcolor' }} />
        </ListItemIcon>
        <ListItemText sx={{ m: 0 }} primary='Data Settings' />
      </ListItemButton>

      {/* ========================== Transaction History ========================== */}
      <ListItemButton
        disableRipple
        selected={selected === 3}
        onClick={() => {
          onClick(3)
        }}
        sx={{
          '&:hover': {
            backgroundColor: 'transparent',
          },
          '&.Mui-selected': {
            borderLeft: '3px solid',
            borderLeftColor: 'primary.main',
            backgroundColor: 'transparent',
            color: 'text.secondary',
            '&:hover': {
              backgroundColor: 'transparent',
            },
            '& .MuiTypography-root': {
              fontWeight: 500,
            },
          },
        }}
      >
        <ListItemIcon sx={{ minWidth: 20, mr: 2 }}>
          <ReceiptLongIcon sx={{ width: 20, height: 20, fill: 'none', stroke: 'currentcolor' }} />
        </ListItemIcon>
        <ListItemText sx={{ m: 0 }} primary='Transaction History' />
      </ListItemButton>
    </List>
  )
}

export default LeftSidebar
