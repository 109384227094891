// version 3

import React, { useEffect, useState } from 'react'
import { IconButton, MobileStepper, styled, CircularProgress } from '@mui/material'
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from '@mui/icons-material'
import Tooltip from '../../../../../components/shared/Tooltip'
import Messages from '..'
import Question from '../../Question'

const StyledMobileStepper = styled(MobileStepper)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.secondary.main,
  borderRadius: 4,
  backgroundColor: theme.palette.background.dark,
  padding: 6,
  minWidth: 96,
}))

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  p: 0,
  width: 24,
  height: 24,
  color: theme.palette.secondary.main,
  '&:hover': {
    backgroundColor: 'transparent',
  },
}))

interface StepperProps {
  activeStep: number
  subSearchArray: any[] // Replace with the actual type of your subSearchArray
  handleStepChange: (step: number) => void
}

const Stepper = ({ activeStep, subSearchArray, handleStepChange }: StepperProps) => {
  // alert('inside Stepper')
  const [localActiveStep, setLocalActiveStep] = useState<number>(activeStep)

  const maxSteps = subSearchArray.length

  useEffect(() => {
    if (subSearchArray.length > 0) {
      setLocalActiveStep(subSearchArray.length - 1)
      handleStepChange(subSearchArray.length - 1)
    }
  }, [subSearchArray])

  const handleNext = () => {
    const nextStep = localActiveStep + 1
    setLocalActiveStep(nextStep)
    handleStepChange(nextStep)
  }

  const handleBack = () => {
    const prevStep = localActiveStep - 1
    setLocalActiveStep(prevStep)
    handleStepChange(prevStep)
  }
  return (
    <>
      <div>
        <Question
          question={subSearchArray[activeStep]?.SearchTitle}
          questionId={subSearchArray[0]?.id}
          index={activeStep}
        />

        <div style={{
          display: 'flex',
          justifyContent: 'center',

        }}>
          <Tooltip title='Responses' placement='bottom'>
            <StyledMobileStepper
              variant='text'
              steps={maxSteps}
              position='static'
              activeStep={activeStep}
              nextButton={
                <StyledIconButton onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                  <ChevronRightIcon />
                </StyledIconButton>
              }
              backButton={
                <StyledIconButton onClick={handleBack} disabled={activeStep === 0}>
                  <ChevronLeftIcon />
                </StyledIconButton>
              }
            />
          </Tooltip>
        </div>


        {/* Display subSearch items based on the active step */}

        <Messages
          question={subSearchArray[activeStep]?.SearchTitle}
          questionId={subSearchArray[0]?.id}
          answer={subSearchArray[activeStep]?.SearchResult}
          historyId={subSearchArray[activeStep]?.id}
          FeedBackstatus={subSearchArray[activeStep]?.FeedBackstatus}
        />
      </div>
    </>
  )
}

export default Stepper
