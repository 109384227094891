import React, { useEffect, useRef, useState } from 'react'
import baseUrl from '../../../../config/baseUrl'
import {
  Drawer,
  Tooltip,
  Alert,
  Grid,
  Snackbar,
  MenuItem,
  Select,
  Typography,
  Box,
  InputAdornment,
  FormControl,
  TextField,
  Button,
  CircularProgress,
  IconButton,
  useMediaQuery,
  Backdrop,
  ListItemText,
  ListItemButton,
  List,
} from '@mui/material'
// import EditNoteIcon from '@mui/icons-material/EditNote'
import ReactMarkdown from 'react-markdown'
import SearchIcon from '@mui/icons-material/Search'
import { SendIcon } from '../../../../components/Icons'
import { useSidebarContext } from '../../../../contexts/Old_SidebarContext'
import CloseIcon from '@mui/icons-material/Close'
// import MenuIcon from '@mui/icons-material/Menu'
// import HomeIcon from '@mui/icons-material/Home'
import { red } from '@mui/material/colors'
// import LawTechLogo from '../../../../assets/images/Lawtech.png'
import SContext from '../../../../contexts/SidebarContext'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import remarkGfm from 'remark-gfm'
import FactCheckIcon from '@mui/icons-material/FactCheck'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow'
import Pagination from '../../../../components/Pagination'
import { ToastContainer, toast } from 'react-toastify'
import CopyButton from '../Messages/Actions/CopyButton'

export default function LeftDrawer() {
  const {
    // answer,
    // sendAnswerToLeftDrawer,
    getHistory,
    simplifyText,
    userStatus,
    handleSearchCount,
    sendData,
    message,
    setMessage,
    // sendAnswerToLeftDrawer,
  } = useSidebarContext()
  const iframeRef = useRef(null)
  const anchor = 'left'

  const [file, setFile] = useState(null)
  const [pdfUrl, setPdfUrl] = useState('')
  const [isLoading, setIsLoading] = React.useState(false)
  const userId = localStorage.getItem('userId')
  // const [inputQuestion, setInputQuestion] = React.useState('')
  const promptRef = React.useRef(null)
  const fileInputRef = useRef(null)
  // const answerRef = React.useRef(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchText, setSearchText] = React.useState('')
  const [showIcon, setShowIcon] = React.useState(false)
  const [backdropOpen, setBackdropOpen] = React.useState(false)

  // Snackbar
  const [snackbarOpen, setSnackbarOpen] = React.useState(false)
  const [snackbarMessage, setSnackbarMessage] = React.useState('')
  const [snackbarSeverity, setSnackbarSeverity] = React.useState('success')
  const [historyData, setHistoryData] = React.useState(null)
  const sendButtonColor = red[500]
  const isSmallScreen = useMediaQuery('(max-width: 600px)')

  const [results, setResults] = useState([])

  // pagination
  const [totalPages, setTotalPages] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)

  const [isCopyPopupVisible, setIsCopyPopupVisible] = useState(false)
  const [selectedText, setSelectedText] = useState('')

  const [state, setState] = React.useState({
    left: false,
  })

  // const [selectedOption, setSelectedOption] = useState('Acts');

  const [loader, setLoader] = useState(false)

  const [verifierAnswer, setVerifierAnswer] = useState(null)
  const [verifierQuestion, setVerifierQuestion] = useState(null)
  const { setOpenVerifier } = React.useContext(SContext)
  const [verifierState, setVerifierState] = React.useState(false)

  React.useEffect(() => {
    getHistoryData()
  }, [])
  // const navigate = useNavigate()

  useEffect(() => {
    // console.log('state', state)
  }, [state])

  useEffect(() => {
    searchText && handleSearchChange()
  }, [currentPage])

  React.useEffect(() => {
    if (file) {
      setBackdropOpen(true)
    }
  }, [file])

  // console.log('sendAnswerToLeftDrawer', sendAnswerToLeftDrawer, inputQuestion)

  const toggleDrawer =
    (anchor = 'left', open = true) =>
      (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return
        }
        if (open === false) {
          setOpenVerifier(false)
          setVerifierState(false)
        }
        setState({ ...state, [anchor]: open })
      }

  const handleSubMenuClick = async (item) => {
    setVerifierQuestion(item)
    // console.log('Hello World', item)

    setLoader(true)

    try {
      const response = await fetch(`${baseUrl}/api/admin/getsection`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          section_number: item,
        }),
      })
      // console.log('verifier response', response)
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`)
      }

      const json = await response.json()
      // console.log('verifier json ', json)
      setVerifierAnswer(json?.data)
      setLoader(false)
      setState({ left: false })
    } catch (error) {
      setLoader(false)
      setVerifierAnswer(null)
      // console.log('error', error)
      toast.error(error)

      // console.log('verifierAnswer', verifierAnswer)
    }
  }

  const handleIframeLoad = () => {
    const iframe = iframeRef.current
    iframe.contentWindow.addEventListener('mouseup', handleSelection)
  }

  const handleSelection = () => {
    const iframe = iframeRef.current
    const selection = iframe.contentWindow.getSelection()
    if (selection && selection.toString()) {
      setSelectedText(selection.toString())
      setIsCopyPopupVisible(true)
    } else {
      setIsCopyPopupVisible(false)
    }
  }

  const handleCopy = () => {
    navigator.clipboard.writeText(selectedText)
    setIsCopyPopupVisible(false)
  }

  const handleCloseBackdrop = () => {
    setFile(null)
    setPdfUrl(null)
    setBackdropOpen(false)
  }

  // console.log('message, answer')
  const getHistoryData = async (index) => {
    // console.log('inputQuestion, userId', inputQuestion, userId, sessionStorage.getItem('userId'))
    const history = await getHistory(userId)
    history && setHistoryData(history)
    // history && console.log(history[index]?.additionalInfo?.SearchResult)
    // console.log('inputQuestion', inputQuestion)
  }

  React.useEffect(() => {
    message && setIsLoading(false)
    message && getHistoryData()

    // answer && answerRef.current.value(answer)
  }, [message])

  const handleToVerifier = async () => {
    setVerifierQuestion('')
    setVerifierAnswer('')
    // console.log('handleToVerifier state', state)
    setState({ ...state, [anchor]: true })
    setVerifierState(true)

    // const objectToSend = {
    //   qs: qs,
    //   ans: ans,
    //   hId: hId,
    // }

    // let queryString = ''
    // for (const key in objectToSend) {
    //   const value = encodeURIComponent(objectToSend[key])
    //   queryString += `${key}=${value}&`
    // }

    // // Remove the trailing ampersand
    // queryString = queryString.slice(0, -1)

    // const url = '/verifier?' + queryString

    // navigate(url)
  }

  const handleFileChange = (event) => {
    // console.log(event.target.files[0])
    if (event.target.files[0]?.name.includes('pdf')) {
      setFile(event.target.files[0])
      const fileUrl = URL.createObjectURL(event.target.files[0])
      fileUrl && setPdfUrl(fileUrl)
    } else {
      setSnackbarMessage('Please choose a .pdf or .docx file')
      setSnackbarSeverity('info')
      setSnackbarOpen(true)
    }

    // console.log('Selected file:', event.target.files[0])
  }

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false)
  }

  const handleUploadFileClick = () => {
    fileInputRef.current.click()
  }

  const handleSendClick = async () => {

    if (promptRef?.current?.value === '') {
      setSnackbarMessage('Please insert a prompt')
      setSnackbarSeverity('warning')
      setSnackbarOpen(true)
      // return
    } else {
      setIsLoading(true)
      // console.log('promptRef?.current?.value', promptRef?.current?.value)

      // promptRef?.current?.value && setInputQuestion(promptRef?.current?.value)
      // const uStatus = await userStatus(userId)
      // uStatus && console.log('uStatus', uStatus)
      // uStatus?.status && handleSearchCount(promptRef?.current?.value, userId)

      const uStatus = await userStatus(userId)
      if (uStatus?.status === false) {
        setSnackbarMessage('Something is wrong with user status!')
        setSnackbarSeverity('error')
        setSnackbarOpen(true)
      } else {
        const promptValue = promptRef.current?.value ?? ''
        const handleSearchCountResponse = await handleSearchCount(promptValue, userId)
        if (handleSearchCountResponse) {
          sendData(promptValue)
        } else {
          setSnackbarSeverity('error')
          setSnackbarOpen(true)
        }
      }
    }
  }

  const handleSelectQuestion = (index) => {
    getHistoryData(index)
    setMessage(simplifyText(historyData[index]?.additionalInfo?.SearchResult))
  }

  const handleSearchChange = async () => {
    if (searchText?.length !== 0) {
      try {
        const res = await fetch(
          `${baseUrl}/api/admin/getSectionsQue?search=${searchText.toLowerCase()}&page=${currentPage}`,
        )
        // console.log('handleSearchChange res', res)

        const data = await res.json()

        if (!res.ok) {
          //   console.log('error');
          //   setSnackbarMessage('Something went wrong while searching');
          //   setSnackbarSeverity('error');
          //   setSnackbarOpen(true);
          //   return
          // } else if (res.status === 404) {
          setSnackbarMessage(data?.message)
          setSnackbarSeverity('info')
          setSnackbarOpen(true)
          return
        }

        // setData(searchResult);
        setTotalPages(data?.totalPages)
        setResults(data?.data)

        // console.log(data);
      } catch (error) {
        // console.log(error);
        toast.error(error)
      }
    }
  }

  const handleChange = (e) => {
    if (e.target.value === '') {
      setShowIcon(false)
    }
    setSearchText(e.target.value)
    setShowIcon(true)
  }

  const handleClearSearch = () => {
    setResults([])
    setTotalPages(1)
    setCurrentPage(1)
    setVerifierQuestion('')
    setVerifierAnswer('')
    setShowIcon(false)
    setSearchText('')
  }

  const handleEnterKeyPress = async (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleSearchChange()
    }
    if (event.key === 'Backspace' || event.key === 'Delete') {
      if (searchText === '') {
        setResults([])
      }
    }
  }

  const handleEnterKey = async (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleSendClick()
    }
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  const MarkdownRenderer = (props) => {
    /* eslint-disable react/prop-types */
    const { textToDisplay } = props
    // console.log('355 textToDisplay', textToDisplay)
    // console.log('message', message)

    return (
      <Box
        style={{
          minHeight: '100%',
          width: '100%',
          overflow: 'auto',
          textAlign: 'justify',
          padding: '10px',
        }}
      >
        <ReactMarkdown remarkPlugins={[remarkGfm]}>{textToDisplay}</ReactMarkdown>
      </Box>
    )
  }

  // if (searchText === '1') {
  //   console.log('searchText', searchText)
  // }

  return (
    <Box sx={{
      position: 'relative',
      minWidth: '100%',
      maxWidth: '100%',
      minHeight: 'calc(100vh - 4rem)',
      // border: '2px solid red'
    }}>
      <ToastContainer />
      {loader && (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            zIndex: '10001',
          }}
        >
          <h1>Loading ...</h1>
          <CircularProgress />
        </Box>
      )}
      <Box
        width='100%'
        position='relative'
        sx={{
          bgcolor: '#f4f6ff',
          minHeight: 'calc(100vh - 4rem)',
        }}
      >
        {/* --------------------- Left Drawer for Verifer search start ---------------------   */}
        <Box>
          <React.Fragment key={anchor}>
            {/* Search Drawer */}
            <Drawer
              anchor={anchor}
              open={state[anchor]}
              PaperProps={{
                sx: { minWidth: { xs: '100%', md: '50%' }, maxWidth: '5%' },
              }}
              // open={true}
              // style={{ maxWidth: '40vw', minWidth: '30vw' }}
              onClose={toggleDrawer(anchor, false)}
            >
              {/*  close drawer button */}
              <Box sx={{ position: 'sticky', top: 0, zIndex: 10, backgroundColor: '#fff' }}>
                <Box
                  style={{
                    padding: '0px 15px',
                    marginTop: '10px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  {/* <Tooltip title='Home' placement='right'>
                    <IconButton
                      style={{ width: '32px', height: '32px' }}
                      aria-label='home'
                    // onClick={() => {
                    //   navigate(objectFromQuery?.hId ? `/NewChat/${objectFromQuery?.hId}` : '/NewChat')
                    // }}
                    >
                      <HomeIcon
                        style={{ color: '#278f42', width: '32px', height: '32px' }}
                        aria-label='home'
                      />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title='Edit' placement='left'>
                    <IconButton
                      style={{ color: '#ff0000', width: '32px', height: '32px' }}
                      aria-label='edit'
                    // onClick={toEditorFromVerifier}
                    >
                      <EditNoteIcon />
                    </IconButton>
                  </Tooltip> */}

                  <Tooltip title='Close' placement='left'>
                    <IconButton
                      style={{ color: '#ff0000', width: '32px', height: '32px' }}
                      aria-label='close'
                      onClick={toggleDrawer(anchor, false)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Box padding='0px 10px'>
                  <TextField
                    value={searchText}
                    name='nameMenuSearchRef'
                    variant='outlined'
                    placeholder='Enter text to search'
                    fullWidth
                    margin='normal'
                    onChange={handleChange}
                    onKeyDown={handleEnterKeyPress}
                    autoComplete='off'
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start' style={{
                          margin: 0,
                          padding: 0
                        }}>
                          <IconButton onClick={handleSearchChange}>
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position='start' style={{
                          margin: 0,
                          padding: 0
                        }}>
                          {showIcon && <Tooltip title='Clear search' placement='right'>
                            <IconButton onClick={handleClearSearch}>
                              <CloseIcon style={{ color: 'red' }} />
                            </IconButton>
                          </Tooltip>}

                        </InputAdornment>
                      ),
                    }}

                    sx={{
                      '& .MuiOutlinedInput-root': {
                        paddingLeft: 1,
                        paddingRight: 1,
                      },
                      '& .MuiOutlinedInput-root .MuiInputBase-input': {
                        paddingLeft: '5px'
                      }
                    }}
                  // value={searchQuery}
                  // onChange={handleSearchChange}
                  // value={searchQuery}
                  // onChange={handleSearchChange}
                  />

                  {/* pagination */}
                  <Grid
                    container
                    style={{
                      display: 'flex',
                      overflow: 'hidden',
                      // position: 'fixed',
                      // bottom: 5,
                      // marginBottom: '20px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: 'transparent',
                      padding: '5px 0 10px 10px',
                      lineHeight: '50px',
                      zIndex: 11,
                    }}
                  >
                    {searchText && (
                      <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                      />
                    )}
                  </Grid>
                </Box>
              </Box>
              <List component='div' disablePadding>
                {results.map((item) => (
                  <ListItemButton
                    sx={{
                      pl: 4,
                      mt: '.25rem',
                      mb: '.2rem',
                      bgcolor: 'rgb(210, 228, 245)',
                      color: '#000',
                      '&:hover': { backgroundColor: 'rgb(200, 218, 235)' },
                    }}
                    key={item}
                    onClick={() => handleSubMenuClick(item)}
                  >
                    <ListItemText primary={item} />
                  </ListItemButton>
                ))}
              </List>
            </Drawer>
          </React.Fragment>
        </Box>
        {/* --------------------- Left Drawer for Verifer search end ---------------------   */}

        {verifierState === true ? (
          <Box sx={{ pt: '4px' }}>
            {/* ------------------Verifier question answer section------------------ */}
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Box
                  style={{
                    minHeight: '100%',
                    minWidth: '100%',
                    // marginTop: '90px',
                  }}
                >
                  <Box sx={{ pt: 1, px: 1 }}>
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <IconButton
                        aria-label='open'
                        onClick={() => {
                          setState({ left: true })
                        }}
                      >
                        <Tooltip title='Open Searchbar' placement='right'>
                          <DoubleArrowIcon sx={{ color: 'navy' }} />
                        </Tooltip>
                      </IconButton>
                      <Typography
                        textAlign={'center'}
                        style={{ marginLeft: '10px', fontWeight: 'bold', color: 'navy' }}
                      >
                        {/* Heading */}
                        Verifier
                      </Typography>
                      <IconButton
                        aria-label='close'
                        onClick={() => {
                          setVerifierState(false)
                        }}
                      >
                        <CloseIcon sx={{ color: 'red' }} />
                      </IconButton>
                    </Box>

                    <hr />

                    <b>
                      {' '}
                      <MarkdownRenderer textToDisplay={verifierQuestion} />
                    </b>
                    {/* Left Drawer for search and Acts/ Judtements/ Formats */}

                    <MarkdownRenderer textToDisplay={verifierAnswer} />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Box sx={{ position: 'relative' }}>
            <Box
              sx={{
                position: 'sticky',
                // top: 56,
                zIndex: 10,
                bgcolor: '#ebeef5',
                // boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1)',
                width: '100%',

              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  paddingTop: '10px',
                }}
              >
                <Typography
                  variant='h6'
                  style={{
                    textAlign: 'left',
                    marginLeft: '10px',
                    fontWeight: 700,
                    color: '#093F6B',
                  }}
                >
                  AI Response
                </Typography>

                <Button // button to upload file
                  startIcon={<FactCheckIcon />}
                  variant='contained'
                  sx={{
                    height: isSmallScreen ? '30px' : '40px',
                    width: isSmallScreen ? '100px' : '140px',
                    marginRight: '10px',

                    color: 'black',
                    fontSize: isSmallScreen ? '10px' : '',
                  }}
                  onClick={handleToVerifier}
                >
                  Verifier
                </Button>
              </div>
              {/*  Input prompt and send button */}
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: '5px 10px',
                }}
              >
                <TextField
                  inputRef={promptRef}
                  size='small'
                  placeholder='Search more...'
                  style={{
                    width: '100%',
                    marginRight: '12px',
                    color: '#000',
                  }}
                  sx={{
                    '& .MuiInputBase-input': {
                      fontSize: '15px !important',
                      padding: '10px 8px !important',
                    },
                    '& .MuiOutlinedInput-root': {
                      paddingLeft: 1,
                      paddingRight: 1,
                    },
                    '& .MuiOutlinedInput-root .MuiInputBase-input': {
                      paddingLeft: '0px',
                    },
                  }}
                  onKeyDown={handleEnterKey}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start' style={{ margin: 0, padding: 0 }}>
                        <IconButton onClick={() => {
                          handleSendClick()
                        }}>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),

                  }}

                />
                <Button
                  disabled={isLoading}
                  variant={!isLoading ? 'contained' : 'outlined'}
                  sx={{
                    bgcolor: { sendButtonColor },
                    minWidth: 40,
                    width: 40,
                    height: 40,
                    p: 0,
                  }}
                  onClick={() => {
                    handleSendClick()
                  }}
                >
                  {isLoading ? <CircularProgress color='warning' /> : <SendIcon />}
                </Button>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  p: '5px 10px',
                  width: '100%',
                  // bgcolor: '#ebeef5',
                  backgroundColor: '#dedede',

                  flexWrap: 'wrap',
                  mt: 0.5,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexShrink: 0, // Prevent shrinking of this box
                  }}
                >
                  <Typography sx={{ mr: 0.5 }}>History</Typography>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexGrow: 1, // This will allow the search box to take the remaining space
                  }}
                >
                  <FormControl sx={{ minWidth: 250, maxWidth: '300px', height: '40px' }}>
                    {' '}
                    <Select
                      sx={{
                        height: '40px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            maxWidth: '500px', // Limit the dropdown width when opened
                          },
                        },
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                      }}
                    >
                      {historyData &&
                        historyData.map((item, index) => (
                          <MenuItem
                            key={index}
                            value={item?.additionalInfo?.SearchTitle}
                            onClick={() => handleSelectQuestion(index)}
                          >
                            {item?.additionalInfo?.SearchTitle}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    flexShrink: 0, // Prevent shrinking of this box
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingLeft: '10px',
                  }}
                >
                  <Typography variant='body2' sx={{ backgroundColor: 'transparent', mr: 0.5 }}>
                    Upload File
                  </Typography>
                  <Button
                    startIcon={<CloudUploadIcon />}
                    variant='contained'
                    sx={{
                      height: isSmallScreen ? '30px' : '40px',
                      width: isSmallScreen ? '120px' : '140px',
                      color: 'black',
                      fontSize: isSmallScreen ? '10px' : '',
                    }}
                    onClick={handleUploadFileClick}
                  >
                    <input
                      ref={fileInputRef}
                      type='file'
                      accept='*.pdf, *.doc, *.docx'
                      style={{ display: 'none' }}
                      onChange={handleFileChange}
                    />
                    Select File
                  </Button>
                </Box>
              </Box>
            </Box>

            {/* history section */}

            {/* Answer section */}
            <Box
              sx={{
                height: '65vh', // Adjust height to fit within the viewport
                overflowY: 'scroll', // Enable vertical scrolling
                padding: '10px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    p: '10px',
                  }}
                >
                  AI Response
                </Typography>
                {message && <CopyButton message={message} />}
              </Box>
              <Box
                style={{
                  border: '1.5px solid rgba(0, 0, 0, 0.12)',
                  borderRadius: '4px',
                  // margin: '10px',
                  // padding: '10px',
                }}
              >
                <MarkdownRenderer textToDisplay={message} />
              </Box>
            </Box>

            <Box>
              {/* <Button onClick={handleOpen}>Show backdrop</Button> */}
              <Backdrop
                sx={{
                  p: '.2rem',
                  width: '50%',
                  color: '#fff',
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={backdropOpen}
              >
                <IconButton
                  variant='h1'
                  color='initial'
                  onClick={handleCloseBackdrop}
                  style={{ zIndex: '1203' }}
                  sx={{
                    color: 'red',
                    bgcolor: '#fff',
                    cursor: 'pointer',
                    position: 'absolute',
                    top: '10px',
                    right: '20px',
                    '&:hover': {
                      bgcolor: '#eee7e7',
                    },
                  }}
                >
                  <CloseIcon />
                </IconButton>

                {/* <CircularProgress color='inherit' /> */}

                {pdfUrl && (
                  <iframe
                    style={{ zIndex: '1202' }}
                    ref={iframeRef}
                    src={pdfUrl}
                    width='100%'
                    height='800'
                    title='PDF Viewer'
                    allowFullScreen
                    onLoad={handleIframeLoad}
                  />
                )}
                {isCopyPopupVisible && (
                  <div className='copy-popup'>
                    <p>Selected text:</p>
                    <pre>{selectedText}</pre>
                    <button onClick={handleCopy}>Copy</button>
                  </div>
                )}
              </Backdrop>
            </Box>
            <div style={{ display: 'grid', justifyContent: 'center' }}></div>
          </Box>
        )}
      </Box>
      {
        snackbarOpen && (
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleCloseSnackbar}
          >
            <Alert elevation={6} onClose={handleCloseSnackbar} severity={snackbarSeverity}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        )
      }
    </Box >
  )
}
