import { Divider, Stack, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import HistoryItem from './HistoryItem'
import { useSidebarContext } from '../../../../contexts'
import moment from 'moment'

const StyledDivider = styled(Divider)({
  fontSize: 14,
  color: 'rgba(0, 0, 0, 0.20)',
  '&:before': {
    height: 1,
    border: 'none',
    background: 'linear-gradient(90deg, rgba(217, 217, 217, 0.00) 0%, #D9D9D9 49.23%)',
  },
  '&:after': {
    height: 1,
    border: 'none',
    background: 'linear-gradient(-90deg, rgba(217, 217, 217, 0.00) 0%, #D9D9D9 49.23%)',
  },
})


const History = () => {
  const { userHistory, searchValue } = useSidebarContext()
  const [selectedQuestionId, setSelectedQuestionId] = useState(0)
  const [history, setHistory] = useState([])
  const [isLoadingHistory, setIsLoadingHistory] = useState(false)

  let lastDate: number | null = null;
  const updatedHIstory = userHistory?.map((item) => item?.additionalInfo)
  // console.log('updatedHIstory', updatedHIstory)
  let filterHistory = updatedHIstory?.filter((item) =>
    item?.SearchTitle.toLowerCase().includes(searchValue.toLowerCase()),
  )
  // console.log('filterHistory', filterHistory)

  useEffect(() => {
    // setIsLoadingHistory(true)
    filterHistory && historyItems()
  }, [filterHistory])

  useEffect(() => {
    if (!searchValue) {
      filterHistory = updatedHIstory?.map((item) =>
        item,
      )
    }
  }, [updatedHIstory])

  // useEffect(() => {
  //   // selectedQuestionId && console.log('selectedQuestionId', selectedQuestionId)
  // }, [selectedQuestionId])

  if (!userHistory || searchValue === undefined) {
    return null // or return loading state or appropriate fallback
  }

  const historyItems: any = () => {
    const hItems: any = filterHistory?.map((item) => {
      // console.log('historyItem', item)
      return (
        <div key={item?.id}>
          {/* <StyledDivider>{moment(item.updatedAt).fromNow()}</StyledDivider> */}

          <StyledDivider>{getTimeLabel(item?.updatedAt)}</StyledDivider>

          <HistoryItem
            title={item?.SearchTitle}
            key={item?.id}
            historyId={item?.id}
            itemId={item?.id}
            threadId={item?.ThreadId}
            selectedQuestionId={selectedQuestionId}
            setSelectedQuestionId={setSelectedQuestionId}
          />
        </div>
      )
    })
    // console.log('hItems', hItems)
    return hItems
  }

  const getTimeLabel = (timestamp: any) => {
    // console.log('timestamp', timestamp)
    const now = moment()
    const targetDate = moment(timestamp)
    // console.log('now targetDate', now, targetDate);
    const diffDays = now.diff(targetDate, 'days')
    // console.log('diffDays', diffDays);
    if (diffDays === 0) {
      if (diffDays === lastDate) {
        return ''
      } else {
        lastDate = diffDays;
        return 'Today'
      }
    } else if (diffDays > 0 && diffDays <= 1 && lastDate !== null) {
      // console.log('diffDays', diffDays);
      if (lastDate > 0 && lastDate < 2) {
        return ''
      } else {
        lastDate = diffDays;
        // console.log('Yesterday');
        return 'Yesterday'
      }
    } else if (diffDays >= 2 && diffDays <= 7 && lastDate !== null) {
      if (lastDate >= 2 && lastDate <= 7) {
        return ''
      } else {
        lastDate = diffDays;
        return 'Past seven days'
      }
    } else if (diffDays > 7 && diffDays <= 31 && lastDate !== null) {
      // console.log('diffDays', diffDays);
      if (lastDate > 7 && lastDate <= 31) {
        return ''
      } else {
        lastDate = diffDays
        // console.log(targetDate.format('MMMM'));
        return targetDate.format('MMMM')
      }
    }
    else if (diffDays > 31 && diffDays <= 61 && lastDate !== null) {
      // console.log('diffDays', diffDays);
      if (lastDate > 31 && lastDate <= 61) {
        return ''
      } else {
        lastDate = diffDays
        // console.log(targetDate.format('MMMM'));
        return targetDate.format('MMMM')
      }

    } else if (diffDays > 61 && diffDays <= 90 && lastDate !== null) {

      if (lastDate > 61 && lastDate <= 90) {
        return ''
      } else {
        lastDate = diffDays
        return 'Before Two months'
      }

    }
  }


  return (
    <Stack spacing={3} sx={{ position: 'relative', margin: 0, padding: '0', boxSizing: 'border-box' }}>
      <Stack spacing={2}>
        <Stack spacing={1} style={{ paddingBottom: '100px' }}>
          {filterHistory?.map((item) => {
            return (
              <div key={item?.id}>
                {/* <StyledDivider>{moment(item.updatedAt).fromNow()}</StyledDivider> */}

                <StyledDivider>{getTimeLabel(item?.updatedAt)}</StyledDivider>

                <HistoryItem
                  title={item?.SearchTitle}
                  key={item?.id}
                  historyId={item?.id}
                  itemId={item?.id}
                  threadId={item?.ThreadId}
                  selectedQuestionId={selectedQuestionId}
                  setSelectedQuestionId={setSelectedQuestionId}
                />
              </div>
            )
          })}
        </Stack>
      </Stack>
    </Stack>

  )
}

export default History
