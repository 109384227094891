import React from 'react'
import { Link as RouteLink } from 'react-router-dom'
import { Dialog, DialogContent, Typography, Box, IconButton, Button } from '@mui/material'
import { Player } from '@lottiefiles/react-lottie-player'
import { CloseIcon } from '../../Icons'

interface Props {
  open: boolean
  onClose: () => void
}

const RestrictedAccessDialog = ({ open, onClose }: Props) => {
  return (
    <Dialog open={open} PaperProps={{ sx: { maxWidth: 523 } }}>
      <IconButton
        disableRipple
        sx={{
          position: 'absolute',
          top: 16,
          right: 16,
          color: 'text.secondary',
          p: 0,
          width: 24,
          height: 24,
        }}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          gap: 4,
          p: 6,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            gap: 3,
            width: 1,
          }}
        >
          <Player
            autoplay
            loop
            src='https://lottie.host/a3a64904-f6c1-4444-bcf3-4a8950fc989b/B96FSvwTP5.json'
            style={{ height: 69, width: 128 }}
          />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <Typography variant='h5'>Restricted Access</Typography>
            <Typography align='center'>
              In free trial, the bot will respond to your queries max. in 500 words
            </Typography>
            <Typography variant='subtitle1' align='center'>
              In order to have access to all the services, Kindly Register first
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row',
            gap: 3,
            width: 1,
          }}
        >
          <Button variant='outlined' sx={{ maxWidth: 70, height: 48 }}>
            Skip
          </Button>
          <Button
            component={RouteLink}
            to='/auth/register'
            variant='contained'
            sx={{ maxWidth: 102, height: 48 }}
          >
            Register
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default RestrictedAccessDialog
