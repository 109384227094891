import React from 'react'
import { SvgIcon, styled, type SvgIconProps } from '@mui/material'

const StyledSvgIcon = styled(SvgIcon)<SvgIconProps>({
  fill: 'none',
  stroke: 'currentcolor',
})

const CheckIcon = (props: SvgIconProps) => (
  <StyledSvgIcon viewBox='0 0 20 20' {...props}>
    <path
      d='M15.0003 5L5.83366 14.1667L1.66699 10'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M18.333 8.3335L12.083 14.5835L10.833 13.3335'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </StyledSvgIcon>
)

export default CheckIcon
