import * as React from 'react'
import { Outlet } from 'react-router-dom'
import { Box, Container, Stack } from '@mui/material'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Breadcrumbs from '../../components/shared/Breadcrumbs'
import SearchQuestion from './SearchQuestion'

const FAQLayout = () => {
  React.useEffect(() => {
    document.title = 'FAQ - LawTech'
  }, [])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        mt: 9,
        bgcolor: 'common.white',
      }}
    >
      <Header maxWidth='false' />
      <Container
        maxWidth={false}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: 'calc(100vh - 136px)',
        }}
      >
        <Stack flexGrow={1} bgcolor='background.dark' px={3.5} py={3} spacing={3.5}>
          <Breadcrumbs />
          {/* <SearchQuestion /> */}
          <Outlet />
        </Stack>
      </Container>
      <Footer small />
    </Box>
  )
}

export default FAQLayout
