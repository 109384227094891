import React from 'react'
import { SvgIcon, styled, type SvgIconProps } from '@mui/material'

const StyledSvgIcon = styled(SvgIcon)<SvgIconProps>({
  fill: 'none',
  stroke: 'currentcolor',
})

const RefreshIcon = (props: SvgIconProps) => (
  <StyledSvgIcon viewBox='0 0 25 24' {...props}>
    <path
      d='M3.5 2V8H9.5'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M21.5 12.0001C21.4984 10.2634 20.9944 8.56429 20.0487 7.10764C19.1031 5.651 17.7562 4.49907 16.1705 3.79079C14.5848 3.08252 12.8281 2.84815 11.1121 3.11596C9.39623 3.38377 7.79445 4.14232 6.5 5.30011L3.5 8.00011'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M21.5 22V16H15.5'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M3.5 12C3.50158 13.7367 4.0056 15.4358 4.95125 16.8925C5.89691 18.3491 7.24382 19.501 8.82951 20.2093C10.4152 20.9176 12.1719 21.152 13.8879 20.8841C15.6038 20.6163 17.2056 19.8578 18.5 18.7L21.5 16'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </StyledSvgIcon>
)

export default RefreshIcon
