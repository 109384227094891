import { useCallback, useEffect, useState } from 'react'

const useCopyToClipboard = (): [(text: string) => void, boolean?] => {
    const [isCopied, setIsCopied] = useState<boolean>(false)
    const copyToClipboard = useCallback(async (text: string) => {
        if ('clipboard' in navigator) {
            await navigator.clipboard.writeText(text)
        } else {
            // fallback solution for older browsers without clipboard API support
            const el = document.createElement('textarea')
            el.value = text;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy')
            document.body.removeChild(el);
        }
        setIsCopied(true);
    }, [])
    useEffect(() => {
        if (isCopied) {
            const timeoutId = setTimeout(() => {
                setIsCopied(false)
            }, 2000)
            return () => { clearTimeout(timeoutId); }
        }
    }, [isCopied])
    return [copyToClipboard, isCopied]
}

export default useCopyToClipboard
