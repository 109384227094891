import React from 'react'
import { SvgIcon, styled, type SvgIconProps } from '@mui/material'

const StyledSvgIcon = styled(SvgIcon)<SvgIconProps>({
  fill: 'none',
  stroke: 'currentcolor',
})

const PlusIcon = (props: SvgIconProps) => (
  <StyledSvgIcon viewBox='0 0 24 24' strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" {...props}>
    <line x1='12' y1='5' x2='12' y2='19'></line>
    <line x1='5' y1='12' x2='19' y2='12'></line>
  </StyledSvgIcon>
)

export default PlusIcon
