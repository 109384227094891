import React from 'react'
import { SvgIcon, type SvgIconProps } from '@mui/material'

const BankOutlinedIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox='0 0 40 40' {...props}>
    <rect width='40' height='40' rx='4' fill='#F6AF45' fillOpacity='0.2' />
    <path
      d='M11 30H29'
      stroke='#233A48'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14 26V19'
      stroke='#233A48'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M18 26V19'
      stroke='#233A48'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M22 26V19'
      stroke='#233A48'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M26 26V19'
      stroke='#233A48'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M20 10L28 15H12L20 10Z'
      stroke='#233A48'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </SvgIcon>
)

export default BankOutlinedIcon
