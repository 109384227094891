import React, { useState, useRef, useEffect } from 'react'
import { Box, Container, Grid, Typography, useMediaQuery } from '@mui/material'
import StartFreeTrial from './StartFreeTrial'
import ImageComponent from '../../../components/shared/Image'
import { useTheme } from '@mui/material/styles'

import { CurvedLineIcon } from '../../../components/Icons'

const MainSection = () => {
  const theme = useTheme()
  const xsMatched = useMediaQuery(theme.breakpoints.down('xs'))
  const smMatched = useMediaQuery(theme.breakpoints.down('sm'))
  const mdMatched = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Container>
      <Box
        sx={{
          mt: mdMatched ? 2 : 2,
          position: 'relative',
          // minHeight: '80dvh',

          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} md={6}>
            <Box style={{ maxWidth: '95%' }}>
              <Typography
                variant='h1'
                // style={{ align: smMatched ? 'center' : 'left' }}
                sx={{
                  px: 2,
                  py: 1,
                  textAlign: mdMatched ? 'center' : 'left',
                  fontWeight: 800,
                  letterSpacing: '0.04em',
                  lineHeight: '60px',
                }}
              >
                Turn Legal Challenges Into Noteworthy Triumphs
              </Typography>
              <Typography
                component='p'
                variant='h4'
                sx={{
                  px: 2,
                  py: mdMatched ? 2 : 1,
                  fontWeight: 400,
                  fontSize: 18,
                  textAlign: mdMatched ? 'center' : 'left',
                }}
              >
                With LawTech you get insights by being part of the real world cases even before you
                start your own practice. Sign up now to start your journey.
              </Typography>

              <StartFreeTrial />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ImageComponent />
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}

export default MainSection
