import React from 'react'
import { SvgIcon, type SvgIconProps } from '@mui/material'

const BotOutlinedIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox='0 0 40 40' {...props}>
    <rect width='40' height='40' rx='4' fill='#5A67D9' fillOpacity='0.2' />
    <g clipPath='url(#clip0_83_737)'>
      <path
        d='M27 19H13C11.8954 19 11 19.8954 11 21V27C11 28.1046 11.8954 29 13 29H27C28.1046 29 29 28.1046 29 27V21C29 19.8954 28.1046 19 27 19Z'
        stroke='#333333'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20 15C21.1046 15 22 14.1046 22 13C22 11.8954 21.1046 11 20 11C18.8954 11 18 11.8954 18 13C18 14.1046 18.8954 15 20 15Z'
        stroke='#333333'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20 15V19'
        stroke='#333333'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_83_737'>
        <rect width='24' height='24' fill='white' transform='translate(8 8)' />
      </clipPath>
    </defs>
  </SvgIcon>
)

export default BotOutlinedIcon
