import React from 'react'
import { SvgIcon, type SvgIconProps } from '@mui/material'

const GoogleIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 23 24" {...props}>
    <g clipPath='url(#clip0_64_1862)'>
      <path
        d='M8.01226 1.25649C5.71421 2.05371 3.73236 3.56685 2.35784 5.57366C0.983317 7.58048 0.28856 9.97519 0.375621 12.406C0.462681 14.8369 1.32697 17.1758 2.84153 19.0791C4.3561 20.9824 6.44111 22.3499 8.79031 22.9807C10.6949 23.4721 12.6903 23.4937 14.605 23.0436C16.3396 22.654 17.9432 21.8206 19.2589 20.625C20.6283 19.3427 21.6222 17.7113 22.1339 15.9064C22.6899 13.9436 22.7888 11.8795 22.4232 9.87251H11.7282V14.309H17.922C17.7982 15.0166 17.533 15.6919 17.1421 16.2946C16.7512 16.8973 16.2428 17.4149 15.6472 17.8165C14.8909 18.317 14.0383 18.6536 13.1441 18.8048C12.2474 18.9716 11.3276 18.9716 10.4309 18.8048C9.52191 18.6171 8.66208 18.2419 7.90625 17.7033C6.69183 16.8436 5.77996 15.6224 5.30078 14.2138C4.81363 12.7787 4.81363 11.2231 5.30078 9.78805C5.64187 8.78219 6.20574 7.86636 6.95031 7.10891C7.80237 6.22619 8.88111 5.59522 10.0682 5.28522C11.2552 4.97522 12.5047 4.99818 13.6796 5.35157C14.5974 5.63318 15.4368 6.12544 16.1305 6.78907C16.8289 6.09428 17.5261 5.39769 18.2221 4.6993C18.5815 4.32375 18.9732 3.96618 19.3272 3.58165C18.268 2.59609 17.0248 1.82916 15.6687 1.32477C13.1993 0.428111 10.4973 0.404014 8.01226 1.25649Z'
        fill='white'
      />
      <path
        d='M8.01227 1.25648C10.4971 0.403423 13.1991 0.426885 15.6688 1.32296C17.025 1.83078 18.2677 2.6014 19.3254 3.59062C18.966 3.97515 18.5869 4.33452 18.2203 4.70827C17.5231 5.40426 16.8265 6.09786 16.1305 6.78906C15.4368 6.12543 14.5975 5.63317 13.6796 5.35156C12.5051 4.99693 11.2557 4.97264 10.0683 5.28137C8.88091 5.5901 7.80152 6.21992 6.94852 7.10171C6.20395 7.85916 5.64008 8.77499 5.29899 9.78085L1.57407 6.89687C2.90736 4.25288 5.21588 2.23043 8.01227 1.25648Z'
        fill='#E33629'
      />
      <path
        d='M0.585783 9.75391C0.785847 8.76163 1.11824 7.8007 1.57406 6.89688L5.29899 9.78805C4.81183 11.2231 4.81183 12.7787 5.29899 14.2138C4.05794 15.1721 2.8163 16.1352 1.57406 17.1031C0.43332 14.8324 0.0854124 12.2453 0.585783 9.75391Z'
        fill='#F8BD00'
      />
      <path
        d='M11.7282 9.8707H22.4232C22.7888 11.8777 22.6899 13.9418 22.1339 15.9046C21.6222 17.7095 20.6283 19.3409 19.2589 20.6232C18.0568 19.6852 16.8493 18.7544 15.6472 17.8165C16.2432 17.4145 16.7518 16.8963 17.1427 16.293C17.5336 15.6896 17.7987 15.0136 17.922 14.3054H11.7282C11.7264 12.8284 11.7282 11.3495 11.7282 9.8707Z'
        fill='#587DBD'
      />
      <path
        d='M1.57227 17.1031C2.81451 16.1448 4.05615 15.1817 5.29719 14.2137C5.77733 15.6229 6.69049 16.8442 7.90625 17.7033C8.66444 18.2394 9.52613 18.6115 10.4363 18.7958C11.333 18.9626 12.2528 18.9626 13.1495 18.7958C14.0437 18.6446 14.8963 18.308 15.6526 17.8075C16.8547 18.7455 18.0622 19.6762 19.2643 20.6142C17.9488 21.8104 16.3451 22.6445 14.6104 23.0346C12.6957 23.4847 10.7003 23.4632 8.7957 22.9717C7.28939 22.5695 5.88239 21.8605 4.66289 20.8891C3.37225 19.8642 2.31803 18.5728 1.57227 17.1031Z'
        fill='#319F43'
      />
    </g>
    <defs>
      <clipPath id='clip0_64_1862'>
        <rect width='23' height='23' fill='white' transform='translate(0 0.5)' />
      </clipPath>
    </defs>
  </SvgIcon>
)

export default GoogleIcon
